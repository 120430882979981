<template>
  <div>
    <TopNav></TopNav>
    <div class="first"></div>
    <div class="second">
      <!-- bounceInLeft -->
      <div class="second-box">
        <div class="second-box1 animated fadeInLeft">
          <div class="second-box1-1">智能诊疗</div>
          <div class="second-box1-2">智能开方提供了一站式的辅助诊断解决方案。系统基于患者的全面病史、当前病情及医生主诉，智能引导四诊采集，确保信息采集的科学性与针对性。通过动态关联的采集逻辑与智能化舌象、面象分析，快速生成高效可靠的中医处方。</div>
        </div>
        <div class="second-box2 animated fadeInRight">
          <img src="../static/img/product/product-img-1.png" height="322" width="613"/>
        </div>
      </div>
      <div v-show="!scroll1" style="width: 100%;height: 570px"></div>
      <div class="second-box bottom-box" v-show="scroll1">
        <div class="second-box3" ref="scrolla">
          <img src="../static/img/product/product-img-2.png" height="395" width="571"/>
        </div>
        <div class="second-box1" ref="scrollb">
          <div class="second-box1-1">常见病诊疗</div>
          <div class="second-box1-2">
            中医常见病诊疗系统，将基层常见病、慢性病诊疗方式进行路径化、标准化，构建名老中医的诊疗思维方式，辅助医生开出疗效可靠的处方，提高诊断效率。
<!--            中医常见病诊疗系统，将中医四诊“望、闻、问、切”每一个选项都进行路径化和标准化，将采集到的患者信息进行大数据分析，模拟医圣张仲景的诊疗思维，辅助医生开具疗效可靠的处方，提高诊断效率。-->
          </div>
        </div>
      </div>
      <div v-show="!scroll2" style="width: 100%;height: 442px"></div>

      <div class="second-box"  v-show="scroll2">
        <div class="second-box1" ref="scrollc">
          <div class="second-box1-1">远程会诊</div>
          <div class="second-box1-2">
            经方云中医远程会诊平台能够提供省市级、国家级中医会诊专家资源，实现对基层医疗单位的中医诊疗支持，促进优质医疗服务下沉，提升基层医疗机构的中医诊疗服务能力，提高疑难重症救治水平。
<!--            经方云中医远程会诊平台可提供省市级、国家级中医专家资源，帮助基层医生提高疑难重症的救治水平，同时也能够提供用药指导，通过传授特色中医诊疗技术，帮助基层医生提高中医诊疗服务能力。-->
          </div>
        </div>
        <div class="second-box2" ref="scrolld">
          <img src="../static/img/product/product-img-3.png" height="338" width="566"/>
        </div>
      </div>
      <div v-show="!scroll3" style="width: 100%;height: 500px"></div>

      <div class="second-box bottom-box" v-show="scroll3">
        <div class="second-box3 animated"  ref="scrolle">
          <img src="../static/img/product/product-img-4.png" height="326" width="593"/>
        </div>
        <div class="second-box1 second-know" ref="scrollf">
          <div class="second-box1-1">知识库</div>
          <div class="second-box1-know">
            <div class="second-box1-know1">
              <div class="second-box1-know1-all">
                <div class="second-box1-know1-tit">12000+</div>
                <div class="second-box1-know1-name">药典</div>
              </div>
              <div class="second-box1-know1-all">
                <div class="second-box1-know1-tit">2000+</div>
                <div class="second-box1-know1-name">方剂</div>
              </div>
            </div>
            <div class="second-box1-know1">
              <div class="second-box1-know1-all">
                <div class="second-box1-know1-tit">10000+</div>
                <div class="second-box1-know1-name">中医药图书</div>
              </div>
              <div class="second-box1-know1-all">
                <div class="second-box1-know1-tit">300+</div>
                <div class="second-box1-know1-name">疾病</div>
              </div>
            </div>
          </div>
          <div class="zskDetail">
            经方云知识库体系整合中医疾病、方剂、中药、中成药、针灸、中医文献、中医音视频等多种资源，通过方便快捷的检索功能和规范使用的分类导航可以快速定位所需资源，帮助用户及时获取丰富的中医药信息，学习参考中医药知识及文献。
<!--            知识库体系涵盖以六经为主线的知识库体系、以辨病为主线的知识库体系、以辨证为主线的辨证知识库体系、以方剂为主线的药理知识库体系、以名医为主线的名医名方知识库体系、以名方为主线的民间祖传秘方知识库体系等知识库体系。能提供中医知识、名医心得、病证专题的教育学习。帮助医生进行中医文化学习、中医辨识学习、中医医案分析、针灸手法介绍、疾病介绍、中医方证学习等丰富的中医学习资源。-->
          </div>
        </div>
      </div>
      <div v-show="!scroll4" style="width: 100%;height: 455px"></div>
      <div class="second-box">
        <div class="second-box1"  ref="scrollg">
          <div class="second-box1-1">远程教育</div>
          <div class="second-box1-4">1000+ <span style="font-size: 20px">音视频教育资源</span></div>
          <div class="second-box1-6">秉承中医药数字教育“智慧管理、智慧沟通、智慧运维”的理念，整合教学、科研、管理、服务等信息化资源，推进中医药数字信息化与教育培训各项工作的深度融合。帮助医生进行中医理论知识学习、中医辨证思维学习、中医适宜技术学习等。</div>
        </div>
        <div class="second-box2" ref="scrollh">
          <img src="../static/img/product/product-img-5.png" height="351" width="346"/>
        </div>
      </div>
    </div>
    <div class="node-img1">
      <div class="top-bg-2">
        <img src="../static/img/home/foot-img.png" height="44" width="44"/>
      </div>
    </div>
    <div class="hzsb">
      <div class="hzsb-tit">经方云中医远程会诊设备</div>
      <div v-show="scroll8">
        <img src="../static/img/product/product-new-2.png" class="img1" ref="scrollgImg" height="520" width="446"/>
        <img src="../static/img/product/product-new-3.png" class="img2" ref="scrollgImg2" height="609" width="591"/>
      </div>
    </div>
    <div class="hzsb2">
      <div v-show="scroll9">
        <img src="../static/img/product/product-new-5.png" ref="scrollgImg3" height="134" width="892"/>
      </div>
    </div>
    <div class="node-img1">
      <div class="top-bg-2">
        <img src="../static/img/home/foot-img.png" height="44" width="44"/>
      </div>
    </div>
    <div class="znsxy">
      <div class="znsxy-tit">智能舌诊仪TU-4K</div>
      <div v-show="scroll10">
        <img src="../static/img/product/product-new-6.png" class="img1" ref="scrollgImg4" height="609" width="571"/>
        <img src="../static/img/product/product-new-8.png" class="img2" ref="scrollgImg5" height="607" width="491"/>
      </div>
    </div>
    <div class="znsxy2">
      <div v-show="scroll11">
        <img src="../static/img/product/product-new-10.png" class="img" ref="scrollgImg6" height="362" width="728"/>
      </div>
    </div>
    <div class="node-img1">
      <div class="top-bg-2">
        <img src="../static/img/home/foot-img.png" height="44" width="44"/>
      </div>
    </div>
    <div class="zwjf">
      <div style="width: 1343px;margin: 0 auto">
          <div class="app" v-show="scrollZwjf">
            <div ref="scrollzw" class="app-r">
              <img src="../static/img/product/zw-l.png" height="633" width="553"/>
            </div>
            <div ref="scrolljf" class="app-l">
              <img src="../static/img/product/zw-logo.png" height="144" width="416"/>
              <div class="app-l-tit">
                掌握经方是集中医方证学习，案例分析，模拟训练中医开方，经方百万知识库为一体,以方便中医方证学习、中医相关知识查询，了解学习名医名家开方思路，提高医生开方质量的平台。
              </div>
              <div class="app-l-list">
                <div class="app-l-item" v-for="(item,i) in zwAppList" :key="i">
                  <div>
                    <img class="app-l-img" :src="item.url" height="80" width="80"/>
                  </div>
                  <div class="app-l-item-txt">
                    <div class="app-l-item-txt1">{{ item.title }}</div>
                    <div class="app-l-item-txt2">{{ item.value }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div class="node-img2">
      <div class="top-bg-2">
        <img src="../static/img/home/foot-img.png" height="44" width="44"/>
      </div>
    </div>
    <div class="three">
      <div style="width: 1313px;margin: 0 auto">
        <div class="app" v-show="scroll5">
          <div ref="scrolli" class="app-l">

            <img src="../static/img/product/product-img-6.png" height="144" width="416"/>
            <div class="app-l-tit">
              经方健康是一款集医疗健康资讯、健康养生课程、个人健康数据、家人健康管理、备忘提醒、每日三餐饮食推荐、健康大数据等功能为一体的掌上健康管家。
            </div>
            <div class="app-l-list">
              <div class="app-l-item" v-for="(item,i) in appList" :key="i">
                <div>
                  <img class="app-l-img" :src="item.url" height="80" width="80"/>
                </div>
                <div class="app-l-item-txt">
                  <div class="app-l-item-txt1">{{ item.title }}</div>
                  <div class="app-l-item-txt2">{{ item.value }}</div>
                </div>
              </div>
            </div>
          </div>
          <div ref="scrollj" class="app-r">
            <img src="../static/img/product/product-img-7.png" height="577" width="480"/>
          </div>
        </div>
      </div>
    </div>
    <div class="node-img2">
      <div class="top-bg-2">
        <img src="../static/img/home/foot-img.png" height="44" width="44"/>
      </div>
    </div>
    <div class="fourth">
      <div class="fy_center_box">
        <img src="../static/img/product/title-2.png" height="39" width="257"/>
      </div>
      <div class="fy_center_box second_top">
        <div class="row">
          <div
              :class="['top_btn',currentIndex==index?'active':'']"
              v-for="(item,index) in personList"
              :key="index"
              @mousemove="changeIndex(index)"
          >{{item.name}}</div>
        </div>
      </div>
      <!-- bounceInLeft -->
      <div
          :class="['table_box animated',isChange?'fadeInLeft':'fadeOutRight']"
          v-for="(p,index) in personList"
          :key="index"
          v-show="currentIndex==index&&isChange"
      >
        <div class="fy_center_box">
          <div class="person">
            <img src="@/static/image/applications/person.png" style="width: 217px;height: 542px" v-show="index==1" />
            <img src="@/static/img/product/img-jiqi.png" style="width: 226px" v-show="index==2" />
<!--            <img src="@/static/image/applications/person1.png" class="personImg" v-show="index==0" />-->
            <img src="@/static/image/applications/person2.png" class="personImg" v-show="index==0" />
          </div>
        </div>
      </div>
      <div class="table_right_box">
        <div
            :class="['table_right animated',currentIndex==index?'fadeInRight':'fadeInLeft']"
            v-for="(p,index) in personList"
            :key="index"
            v-show="currentIndex==index&&isChange"
        >
          <p class="des">{{p.des}}</p>
          <img :src="p.imgUrl" class="row_des" />
        </div>
      </div>
    </div>
    <div class="node-img1">
      <div class="top-bg-2">
        <img src="../static/img/home/foot-img.png" height="44" width="44"/>
      </div>
    </div>
    <div class="five">
      <img ref="scrollk" style="margin-bottom: 30px" src="../static/img/product/title-3.png" height="39" width="199"/>
      <div class="fy_center_box">
        <div class="five-list">
          <div class="five-item" v-for="(item,i) in advantageList" :key="i">
            <img :src="item.url" height="100" width="100"/>
            <div class="five-item1">{{ item.title }}</div>
            <div class="five-item2">{{ item.value }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="node-img2">
      <div class="top-bg-2">
        <img src="../static/img/home/foot-img.png" height="44" width="44"/>
      </div>
    </div>
    <div class="six">
      <img ref="scrollm" src="../static/img/product/title-1.png" height="39" width="159"/>

      <div class="fy_center_box six-box-middle">
        <img src="../static/img/product/product-icon-13.png" height="70" width="1100"/>
      </div>
      <div class="fy_center_box six-box">
        <div class="six-box-1" @click="goyun">
          前往体验
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import TopNav from "@/components/Nav/TopNav.vue";
import Footer from "@/components/Footer/Footer.vue";

export default {
  name: "Product",
  data() {
    return {
      lists: [1, 2, 3],
      personList: [
        {
          name: "桌面机器人",
          des: "桌面机器人可以有效的优化就诊流程，智能分诊，提高医生服务效率。可以帮助患者合理分诊、排队，利用患者候诊时间可以帮助患者建立健康档案、智能四诊采集等。支持自然语言、图像、动作等多种交互方式，患者不仅可以与其语音互动，还可以按照导航在电子屏上直接互动完成问诊、望诊等体征数据采集，让患者轻松享受到智慧医疗的全新体验。",
          imgUrl: require("@/static/img/product/AI-3.png")
        },
        {
          name: "患者机器人",
          des:'智能轻问诊解决方案在现有轻问诊流程中植入AI能力，通过打通智能预问诊和智能辅助建议系统，提高医生服务效率。智能轻问诊解决方案在现有轻问诊流程中植入AI能力。',
          imgUrl: require("@/static/img/product/AI-1.png")
        },
        {
          name: "家庭机器人",
          des: "家庭健康管理机器人为全家成员提供全面的、个性化的健康管理。通过强大的“望闻问切”能力，依据“经方云中医大脑”和“经方云临床中医大数据系统”，实时对个体体质做出健康预警、未病预测等，并能在初期阶段做出科学的干预措施、膳食营养合同调整、合理运动指导建议等一体化方案，尤其在预防保健、健康养生、慢病防治等方面效果独特。",
          imgUrl: require("@/static/img/product/AI-2.png")
        }
      ],
      swiperOption: {},
      appList:[
        {title:'课程',value:'名师大咖讲解各科经典病例课程，随时观看健康养生视频，学习健康知识，掌握生活中养生小技巧。',url:require('@/static/img/product/product-icon-1.png')},
        {title:'资讯',value:'海量健康资讯，科普健康养生知识，了解疾病预防方法，帮助发现健康隐患；更早一步进行未病预防。',url:require('@/static/img/product/product-icon-2.png')},
        {title:'健康数据',value:'掌上健康管家，实时监测身体健康状况，掌握身体机能及运行状态，对自身健康了解更全面。',url:require('@/static/img/product/product-icon-3.png')},
        {title:'膳食建议',value:'为您提供合理健康的膳食推荐，让您吃的健康，日常中完成养生。',url:require('@/static/img/product/product-icon-4.png')},
        {title:'用药复诊提醒',value:'智能设置用药提醒，准时准点，定药定量，为您的健康增添“备忘”，让健康不再被遗忘；复诊提醒，为您记录复诊时间、疾病、医院，用心照顾您的健康。',url:require('@/static/img/product/product-icon-5.png')},
        {title:'我的家人',value:'随时随地掌握全家人的健康状态，让关心不再等待，不仅为自己，更为家人，更为爱。家人的健康永远是最大的期望。',url:require('@/static/img/product/product-icon-6.png')},
      ],
      zwAppList:[
        {title:'临证学习',value:'以海量真实世界病例数据为基础，不同疾病症状展示不同诊断路径，名医名家真实案例分析讲解。',url:require('@/static/img/product/zw-1.png')},
        {title:'模拟训练',value:'灵活应用不同场景，模拟名医案例开方训练，记录分析错误原因，强化训练提高开方质量。',url:require('@/static/img/product/zw-2.png')},
        {title:'经方知识库',value:'药典、方剂书籍、针灸、医案、疾病，百万知识库，让您随时随地学习。',url:require('@/static/img/product/zw-3.png')},
      ],
      advantageList:[
        {title:'客观标准',value:'经方云平台遵循“理-法-方-药”“辨证论治”理论原则，充分吸收历代方剂精髓，将中医诊疗方式路径化、标准化。',url:require('@/static/img/product/product-icon-7.png')},
        {title:'培养人才',value:'经方云提供在线的辅助学习和辅助诊疗，使更多普通医师能够进一步融入到医圣张仲景的诊治过程中，帮助普通医生提升诊疗能力。',url:require('@/static/img/product/product-icon-8.png')},
        {title:'人工智能',value:'经方云系统支持自然语言、人脸识别、图像、动作等多种交互方式，患者进行互动在线上完成问诊、望诊等体征数据采集，让患者轻松享受到智慧医疗的全新体验。',url:require('@/static/img/product/product-icon-9.png')},
        {title:'资源共享',value:'云平台模拟名医专家的辨证治疗方式，给出合理的方药建议，让名医专家资源更广泛的服务于广大患者，实现资源共享。',url:require('@/static/img/product/product-icon-10.png')},
        {title:'数据分析',value:'收录历代名医名家著作以及国医大师、名医名家经验智慧，依托经方云中医大数据分析系统进行分析。',url:require('@/static/img/product/product-icon-11.png')},
        {title:'精准度高',value:'技术创新团队和权威专家团队相结合，将经方研究的成果与AI技术、大数据及云计算完美对接，经过多年反复临床试验，反馈修改，保障结果精准度。',url:require('@/static/img/product/product-icon-12.png')},

      ],
      mySwiper: {},
      mySwiper1: {},
      currentIndex: 0,
      isChange: true,
      flag: false,
      scroll1:false,
      scroll2:false,
      scroll3:false,
      scroll4:false,
      scrollZwjf:false,
      scroll5:false,
      scroll6:false,
      scroll7:false,
      scroll8:false,
      scroll9:false,
      scroll10:false,
      scroll11:false,
    };
  },
  mounted() {
    // this.handleScroll()
    window.addEventListener('scroll',this.handleScroll,true)

  },
  methods: {
    handleScroll(){
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // console.log(scrollTop)
      if (scrollTop >= 400 && !this.scroll1) {
        this.scroll1 = true
        this.$refs.scrolla.classList.add('animated', 'fadeInLeft')
        this.$refs.scrollb.classList.add('animated', 'fadeInRight')
      }
      if (scrollTop >= 800 && !this.scroll2) {
        this.scroll2 = true
        this.$refs.scrollc.classList.add('animated', 'fadeInLeft')
        this.$refs.scrolld.classList.add('animated', 'fadeInRight')
      }
      if (scrollTop >= 1200 && !this.scroll3) {
        this.scroll3 = true
        this.$refs.scrolle.classList.add('animated', 'fadeInLeft')
        this.$refs.scrollf.classList.add('animated', 'fadeInRight')
      }
      if (scrollTop >= 1600 && !this.scroll4) {
        this.scroll4 = true
        this.$refs.scrollg.classList.add('animated', 'fadeInLeft')
        this.$refs.scrollh.classList.add('animated', 'fadeInRight')
      }
      if (scrollTop >= 2400 && !this.scroll8) {
        this.scroll8 = true
        this.$refs.scrollgImg.classList.add('animated', 'fadeInLeft')
        this.$refs.scrollgImg2.classList.add('animated', 'fadeInRight')
      }
      if (scrollTop >= 3200 && !this.scroll9) {
        this.scroll9 = true
        this.$refs.scrollgImg3.classList.add('animated', 'fadeInRight')
      }
      if (scrollTop >= 3800 && !this.scroll10) {
        this.scroll10 = true
        this.$refs.scrollgImg4.classList.add('animated', 'fadeInLeft')
        this.$refs.scrollgImg5.classList.add('animated', 'fadeInRight')
      }
      if (scrollTop >= 4700 && !this.scroll11) {
        this.scroll11 = true
        this.$refs.scrollgImg6.classList.add('animated', 'fadeInRight')
      }
      // app zwjf
      if (scrollTop >= 5200 && !this.scrollZwjf) {
        this.scrollZwjf = true
        this.$refs.scrollzw.classList.add('animated', 'fadeInLeft')
        this.$refs.scrolljf.classList.add('animated', 'fadeInRight')
      }
      // app jfjk
      // if (scrollTop >= 2400 && !this.scroll5) {
      if (scrollTop >= 6100 && !this.scroll5) {
        this.scroll5 = true
        this.$refs.scrolli.classList.add('animated', 'fadeInLeft')
        this.$refs.scrollj.classList.add('animated', 'fadeInRight')
      }
      // if (scrollTop >= 3743 && !this.scroll6) {
      if (scrollTop >= 4543 && !this.scroll6) {
        this.scroll6 = true
        this.$refs.scrollk.classList.add('animated', 'fadeIn')
      }
      // if (scrollTop >= 4425 && !this.scroll7) {
      if (scrollTop >= 5225 && !this.scroll7) {
        this.scroll7 = true
        this.$refs.scrollm.classList.add('animated', 'fadeIn')
      }
    },
    changeIndex(index) {
      // if (this.isChange) {
      // return;
      // }
      this.currentIndex = index;
    },
    goyun(){
      location.href = "https://yun.jingfang.net"
    }
  },
  watch: {
    currentIndex(val) {
      this.isChange = true;
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll',this.handleScroll,true)
  },
  components: {
    TopNav,
    Footer
  }
};
</script>
<style lang="scss" scoped>
.first {
  width: 100%;
  height: 440px;
  margin-top: 80px;
  background: url("./../static/img/product/product-bg.png") no-repeat center;
  //background-size: cover;
}
.second {
  position: relative;
  padding-top: 110px;
  padding-bottom: 80px;
  width: 1200px;
  margin: 0 auto;
  height: 2350px;
  text-align: center;
  //overflow: hidden;
  .second-box{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 100px;
    .second-box1{
      width: 460px;
      .second-box1-1{
        color: #536981;
        font-size: 32px;
        font-weight: 500;
        margin-bottom: 20px;
        text-align: left;
      }
      .second-box1-2{
        color: #8091A5;
        font-size: 16px;
        text-indent: 40px;
        text-align: left;
        line-height: 30px;
      }
      .second-box1-know{
        .second-box1-know1{
          .second-box1-know1-all{
            display: inline-block;
            width: 230px;
            text-align: left;
            margin-bottom: 10px;

            .second-box1-know1-tit{
              font-size: 36px;
              font-weight: 600;
              width: 150px;
              text-align: center;
              color: #AB7D3E;
            }
            .second-box1-know1-name{
              width: 140px;
              color: #666666;
              font-size: 20px;
              text-align: center;

            }
          }
        }
      }
      .second-box1-4{
        font-size: 36px;
        font-weight: 600;
        //width: 150px;
        text-align: left;
        color: #AB7D3E;
        line-height: 32px;
        margin-top: 22px;
      }
      .second-box1-5{
        font-size: 20px;
        text-align: left;
        color: #666;
        margin: 10px 0;
      }
      .second-box1-6{
        font-size: 16px;
        color: #8091A5;
        text-align: left;
        line-height: 30px;
        //margin-top: 30px;

      }

    }
    .second-know{
      padding-bottom: 50px;
    }
    .second-box2{
      width: 615px;
    }
    .second-box3{
      width: 400px;
    }
  }
  .bottom-box{
    margin-top: 70px;
  }
  .second_top {
    position: relative;
    height: 60px;
    margin-top: 60px;
    z-index: 9;
    .row {
      position: absolute;
      right: 10px;
      display: flex;
      justify-content: space-between;
      width: 640px;
      height: 100%;
      overflow: hidden;
      .top_btn {
        display: inline-block;
        width: 190px;
        height: 100%;
        line-height: 60px;
        font-size: 18px;
        text-align: center;
        color: #3565d7;
        background-image: url("./../static/image/applications/top_btn1.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        &:hover {
          cursor: pointer;
        }
      }
      .top_btn.active {
        background-image: url("./../static/image/applications/top_btn.png");
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .title {
    font-size: 30px;
    color: #191e4a;
    letter-spacing: 4px;
  }

  .table_box {
    position: absolute;
    top: 230px;
    left: 0;
    width: 100%;
    overflow: hidden;
    .person {
      position: relative;
      top: 20px;
      display: block;
      float: left;
      width: 500px;
      height: 550px;
      overflow: hidden;
      margin-top: 0px;
      // padding-left: 200px;
      img {
        width: 500px;
        height: 550px;
      }
    }
  }
  .table_right_box {
    width: 1200px;
    white-space: nowrap;
    margin: 0 auto;
  }

  .table_right {
    display: inline-block;
    width: 1200px;
    height: 556px;
    // margin: 0 auto;
    padding-left: 180px;
    padding-top: 30px;
    white-space: normal;
    .des {
      display: block;
      width: 640px;
      color: #5b607f;
      letter-spacing: 1.2px;
      text-align: left;
      line-height: 36px;
      float: right;
    }
    .row_des {
      display: block;
      width: 540px;
      height: 260px;
      margin-top: 30px;
      margin-right: 50px;
      float: right;
    }
  }
}

.zwjf {
  width: 100%;
  min-width: 1200px;
  height: 800px;
  background: url("./../static/img/product/zw-bg1.png") no-repeat center;
  //background-size: cover;
  //padding-top: 80px;
  .app{
    display: flex;
    justify-content: left;
    align-items: center;

    .app-r{
      margin-right: 90px;
      padding-top: 120px;
    }
    .app-l{
      width: 700px;
      margin-right: 30px;
      text-align: left;
      .app-l-tit{
        width: 590px;
        color: #8091A5;
        font-size: 16px;
        line-height: 26px;
        text-indent: 40px;
        padding-left: 20px;
      }
      .app-l-list{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 20px;
        margin-left: 40px;
        .app-l-item{
          width: 506px;
          display: flex;
          justify-content: left;
          align-items: first;
          margin-bottom: 20px;
          .app-l-img{
            position: relative;
            top: 10px;
            left: 0;
          }
          .app-l-item-txt{
            margin-left: 20px;
            .app-l-item-txt1{
              font-size: 18px;
              color: #284E7A;
              font-weight: 700;
              margin-bottom: 10px;

            }
            .app-l-item-txt2{
              min-height: 60px;
              overflow: hidden;
              color: #8091A5;
              font-size: 14px;

            }
          }
        }
      }
    }
  }
}
.hzsb {
  width: 1920px;
  margin: 0 auto;
  min-width: 1200px;
  height: 875px;
  background: url("./../static/img/product/product-new-1.png") no-repeat left;
  position: relative;
  .hzsb-tit {
    text-align: center;
    color: #333333;
    font-size: 40px;
    line-height: 36px;
    letter-spacing: 3px;
    padding-top: 66px;
  }
  .img1 {
    position: absolute;
    left: 418px;
    top:214px
  }
  .img2 {
    position: absolute;
    right: 339px;
    top: 180px;
  }
}
.hzsb2  {
  width: 1920px;
  min-width: 1200px;
  margin: 0 auto;
  height: 500px;
  background: url("./../static/img/product/product-new-4.png") no-repeat center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.znsxy {
  width: 1920px;
  margin: 0 auto;
  min-width: 1200px;
  height: 875px;
  background: url("./../static/img/product/product-new-7.png") no-repeat right;
  position: relative;
  .znsxy-tit {
    text-align: center;
    color: #333333;
    font-size: 40px;
    line-height: 36px;
    letter-spacing: 3px;
    padding-top: 66px;
  }
  .img1 {
    position: absolute;
    left: 356px;
    top:192px
  }
  .img2 {
    position: absolute;
    right: 472px;
    top: 192px;
  }
}
.znsxy2  {
  width: 1920px;
  min-width: 1200px;
  margin: 0 auto;
  height: 700px;
  background: url("./../static/img/product/product-new-9.png") no-repeat center;
  position: relative;
  img {
    position: absolute;
    right: 116px;
    top: 169px;
  }
}
.three {
  width: 100%;
  min-width: 1200px;
  height: 800px;
  background: url("./../static/img/product/bg-1.png") no-repeat center;
  //background-size: cover;
  padding-top: 80px;
  .app{
    display: flex;
    justify-content: left;
    align-items: center;
    .app-l{
      width: 700px;
      margin-right: 30px;
      text-align: left;
      .app-l-tit{
        width: 590px;
        color: #8091A5;
        font-size: 16px;
        line-height: 26px;
        text-indent: 40px;
      }
      .app-l-list{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 20px;
        .app-l-item{
          width: 330px;
          display: flex;
          justify-content: left;
          align-items: first;
          margin-bottom: 20px;
          .app-l-img{
            position: relative;
            top: 10px;
            left: 0;
          }
          .app-l-item-txt{
            margin-left: 20px;
            .app-l-item-txt1{
              font-size: 18px;
              color: #284E7A;
              font-weight: 700;
              margin-bottom: 10px;

            }
            .app-l-item-txt2{
              min-height: 60px;
              overflow: hidden;
              color: #8091A5;
              font-size: 14px;

            }
          }
        }
      }
    }
  }
}
.fourth {
  position: relative;
  padding-top: 110px;
  padding-bottom: 80px;
  height: 870px;
  text-align: center;
  overflow: hidden;
  .second_top {
    position: relative;
    height: 60px;
    margin-top: 60px;
    z-index: 9;
    .row {
      position: absolute;
      right: 10px;
      display: flex;
      justify-content: space-between;
      width: 640px;
      height: 100%;
      overflow: hidden;
      .top_btn {
        display: inline-block;
        width: 190px;
        height: 100%;
        line-height: 60px;
        font-size: 18px;
        text-align: center;
        color: #AB7D3E;
        background-image: url("./../static/img/product/p-bg1.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        //background-color: #666666;
        &:hover {
          cursor: pointer;
        }
      }
      .top_btn.active {
        background-image: url("./../static/img/product/p-bg2.png");
        color: #fff;
      }
    }
  }
  .title {
    font-size: 30px;
    color: #191e4a;
    letter-spacing: 4px;
  }

  .table_box {
    position: absolute;
    top: 230px;
    left: 0;
    width: 100%;
    overflow: hidden;
    .person {
      position: relative;
      top: 20px;
      display: block;
      float: left;
      width: 500px;
      height: 550px;
      overflow: hidden;
      margin-top: 0px;
      // padding-left: 200px;
      img {
        width: 500px;
        height: 550px;
      }
    }
  }
  .table_right_box {
    width: 1200px;
    white-space: nowrap;
    margin: 0 auto;
  }

  .table_right {
    display: inline-block;
    width: 1200px;
    height: 556px;
    // margin: 0 auto;
    padding-left: 180px;
    padding-top: 30px;
    white-space: normal;
    .des {
      display: block;
      width: 640px;
      color: #5b607f;
      letter-spacing: 1.2px;
      text-align: left;
      line-height: 36px;
      float: right;
    }
    .row_des {
      display: block;
      width: 540px;
      height: 260px;
      margin-top: 30px;
      margin-right: 50px;
      float: right;
    }
  }
}
.five {
  width: 100%;
  min-width: 1200px;
  height: 689px;
  background: url("./../static/img/product/bg-2.png") no-repeat center;
  //background-size: cover;
  padding-top: 60px;
  .five-list{
    width: 1200px;
    .five-item{
      width: 400px;
      display: inline-block;
      position: relative;
      text-align: center;
      height: 270px;
      .five-item1{
        color: #284E7A;
        font-size: 18px;
        margin-top: 10px;
        font-weight: 600;
      }
      .five-item2{
        color: #8091A5;
        padding: 10px 40px 60px 40px;
        font-size: 14px;
        position: absolute;

      }
    }
  }
}
.six {
  width: 100%;
  min-width: 1200px;
  height: 410px;
  padding-top: 50px;
  .six-box-middle{
    margin: 70px auto;
  }
  .six-box{
    .six-box-1{
      display: inline-block;
      line-height: 60px;
      color: white;
      cursor: pointer;
      font-size: 20px;
      width: 180px;
      height: 60px;
      padding-right: 40px;
      background: url("./../static/img/product/bot-1.png") no-repeat center;

    }

  }
}

.node-img1{
  width: 100%;
  height: 30px;
  text-align: center;
  position: relative;
  top: 10px;
  z-index: 99;
}
.node-img2{
  width: 100%;
  height: 30px;
  text-align: center;
  position: relative;
  top: -22px;

}
.zskDetail {
  color: #8091A5;
  font-size: 16px;
  text-align: left;
  margin-top: 30px;
  line-height: 30px;
}
</style>
